import ApiService from "@/core/services/api.service";

export const GetPermission = (userType) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`permission?users_type=${userType}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateRole = (data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`role`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetUserPermission = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`user/${uuid}/permission`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GetRoles = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`role`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateRole = (data, roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`role/${roleId}`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const AssignedRolePermission = (data, roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`role/${roleId}/permission`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const AssignedUserPermission = (data, userId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`user/${userId}/permission`, { permission: data })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetRole = (roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`role/${roleId}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
